<template>
  <base-dialog
    :visible.sync="currentVisible"
    width="1200px"
    class="resetDialog"
    :showFooter="false"
    title="补录生成合同数据"
  >
    <div style="padding: 20px">

          <base-form
            :componentList="newPublicFormConfig"
            :formAttrs="{
              model: queryParas,
              labelWidth: '90px',
            }"

            class="formStyle"
            :showBtns="false"
            ref="publicFormData"
          >
          </base-form>

      <div v-for="(item, index) of formConfig" :key="index">
        <title-name-slot :title="item.documentName" v-if="item.config.length"> </title-name-slot>
        <div v-for="(elem,indexon) of item.childen" :key="indexon">
          <div class="groupGysName">{{elem[0].groupGysName}}</div>
          <base-form
            v-if="item.config.length > 0"
            :componentList="elem"
            :formAttrs="{
              model: queryParas,
              labelWidth: '90px',
            }"
            :key="index"
            class="formStyle"
            :showBtns="false"
            ref="formData"
          >
          </base-form>
        </div>
      </div>
    </div>
    <template slot="footer">
      <base-button label="保 存" @click.prevent="handHtSave"></base-button>
      <base-button label="提 交" @click.prevent="handHtCommit"></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="close"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import baseButton from '@/components/common/button/base-button/base-button.vue'
import titleNameSlot from '@/pages/dashboard/components/title-name-slot.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
export const buildInitItem = (data = []) => {
  const empty = Object.create(null)
  data.forEach(it => {
    const { prop, value } = it
    empty[prop] = value
  })
  return empty
}
export default {
  components: { BaseDialog, BaseForm, titleNameSlot, baseButton },
  props: {
    publicFormConfig: Array,
    selctfile: Array,
    visible: Boolean
  },
  computed: {
    // formConfig () {
    //   return this.selctfile
    // },
    formConfig: {
      get () {
        return this.selctfile
      },
      set () {

      }
    },
    newPublicFormConfig: {
      get () {
        return this.publicFormConfig
      },
      set () {

      }
    },
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {

    visible (val) {
      if (val) {
        this.$nextTick(() => {
          const dom = this.$refs.formData
          const publicDom = this.$refs.publicFormData
          // 清除验证
          if (dom && dom.length > 0) {
            dom.forEach((item) => {
              item.clearValidate()
            })
          }
          if (publicDom) {
            publicDom.clearValidate()
          }
        })
      }
    }
  },
  data () {
    return {
      newqueryParasArr: [],
      queryParas: {}
    }
  },
  methods: {
    close () {
      this.$confirm('是否确认关闭?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.queryParas = {}
        this.currentVisible = false
      }).catch(() => {

      })
    },
    buildInitData (data) {
      data.forEach((child, ids1) => {
        const { childen } = child
        const emptyArr = []
        childen.forEach((son, ids2) => {
          const empty = buildInitItem(son)
          emptyArr.push(empty)
        })
        this.$set(this.queryParasArr, ids1, emptyArr)
      })
    },
    handHtSave () {
      this.$emit('handHtSave', '')
    },
    handHtCommit () {
      // 验证
      let status = true
      if (this.$refs.formData) {
        this.$refs.publicFormData.validate(valid => {
          if (!valid) {
            status = false
          }
        })
        this.$refs.formData.forEach((item) => {
          item.validate(valid => {
            if (!valid) {
              status = false
            }
          })
        })
      }
      if (!status) {
        return
      }
      this.$emit('handHtCommit', '')
    }
  }
}
</script>
<style lang="scss" scoped>
.groupGysName{
padding-top:10px ;
}

.resetDialog {
  /deep/ .el-dialog {
    margin-top: 12vh !important;
  }
  /deep/ .el-dialog__body {
    height: 300px;
    overflow-x: hidden;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
/deep/ .el-tag {
  height: 100%;
  white-space: normal;
}
/deep/ .el-select__tags{
  width: 100%;
  max-height: 65px;
  overflow-y: scroll;
}
</style>
